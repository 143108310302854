import './App.css';
import Home from './Components/HomePage/Home';

function App() {  
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
